.input {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.input input {
  display: block;
  width: 100%;
  padding: 0.25rem;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
