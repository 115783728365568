@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

:root {
  --primary-color: red;
  --secondary-color: yellow;
  --bg-color: #282c34;
}

html {
  font-size: calc(10px + 1vmin);
  line-height: 1.4;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: var(--bg-color);
}

html,
body,
#root,
.App {
  height: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  font: inherit; /* Get rid of all font sizes and heights */
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.06rem;
}

body,
p,
ol,
ul {
  font-size: 1rem;
}

ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

img {
  max-width: 100%;
  height: auto;
}

/* a-tag */

a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  color: inherit;
}

textarea,
button {
  color: inherit;
  border: 0;
  box-shadow: none;
  background: transparent;
  text-shadow: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
}

button:focus {
  outline: 0 !important;
}

button:disabled {
  opacity: 0.5;
  cursor: auto;
}

textarea {
  outline: 0 !important;
  display: block;
  border: none;
  width: 100%;
}

.small {
  font-size: 0.8em;
}
