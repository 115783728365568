.Editor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Editor__header {
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  flex-shrink: 0;
}

.Editor__header-line {
  display: flex;
}

.Editor__header-line + .Editor__header-line {
  margin-top: 0.5rem;
}

.Editor__header-info {
  padding-right: 1rem;
}

.Editor__editor {
  flex-shrink: 1;
  height: 0;
  padding: 1rem;
  flex-grow: 1;
  overflow-y: auto;
}

.Editor__editor-inner {
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.Editor__editor > * {
  height: 100%;
}

.Editor__header-action + .Editor__header-action {
  margin-left: 1rem;
}
