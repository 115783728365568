.Login--isNotLoggedIn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Login--isLoggedIn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.8rem;
}
