.FilePreview {
  display: block;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid white;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 1.4rem;
  padding-right: 0.3rem;
  height: 4rem;
  position: relative;
}

.FilePreview--dirty:before {
  content: "";
  position: absolute;
  top: 0.8rem;
  left: 0.3rem;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: white;
}



.FilePreview--selected {
  background-color: white;
  color: black;
}

.FilePreview--selected.FilePreview--dirty:before {
  background-color: black;
}
