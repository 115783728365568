.md {
}

.md__bold {
  font-weight: bold;
}

.md__italic {
  font-style: italic;
}

.md__underline {
  text-decoration: underline;
}

.md__code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.md__title {
  display: inline-block;
  font-weight: bold;
  /* font-size: 20px; */
  /* margin: 20px 0 10px 0; */
}

.md__list {
  padding-left: 0;
  /* font-size: 20px; */
  /* line-height: 10px; */
}

.md__hr {
  display: block;
  /* text-align: center; */
  /* border-bottom: 1px solid #aaa; */
  background-color: rgba(255, 255, 255, 0.05);
}

.md__blockquote {
  display: inline-block;
  border-left: 2px solid #ddd;
  padding-left: 10px;
  color: #aaa;
  margin-right: -10px;

  transform: translateX(-12px);
  /* font-style: italic; */
}

/* I don't know why we have two in a row... */
.md__blockquote + .md__blockquote {
  border-left: none;
  padding-left: 0;
  transform: none;
  margin-right: 0;
}

.md__code {
  font-family: monospace;
  /* background-color: #eee; */
  padding: 3px;
}
