.Files {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
}

.Files__list {
  max-width: 20rem;
  min-width: 14rem;
  flex-basis: 20%;
  flex-shrink: 0;
  flex-grow: 1;
  border-right: 1px solid white;
  height: 100%;
  /* box-sizing: border-box; */
  /* overflow: auto; */
  display: flex;
  flex-direction: column;
}

.Files__editor {
  flex-basis: 80%;
  flex-shrink: 1;
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.Files__list_header {
  font-size: 0.8rem;
  padding: 1rem;

  background-color: rgba(255, 255, 255, 0.1);
}

.Files__list_header-action {
  flex-shrink: 0;
  display: flex;
}

.Files__list-items {
  flex-grow: 1;
  overflow-y: auto;
}
