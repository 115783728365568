.Button + .Button {
  margin-left: 1rem;
}

.Button {
  border-radius: 0.2rem;
  border: 1px solid white;
  padding: 0.3rem 0.6rem;
}
.Button:focus {
  background-color: rgba(255, 255, 255, 0.2);
}
.Button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
